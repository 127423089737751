import React, { createContext, useContext, useState, useEffect } from 'react';
import { get } from 'aws-amplify/api';

// Context for pricing data
const PriceContext = createContext();

// Provider component to manage pricing state
export const PriceProvider = ({ children }) => {
  const [prices, setPricingData] = useState([]);

  const fetchPrices = async () => {
    try {
      const restOperation = get({
        apiName: 'apiArigusService',
        path: `/price`,
      });
      const { body } = await restOperation.response;
      const data = await body.json();
      setPricingData(data);
    } catch (e) {
      console.error('GET call failed for price:', e);
      return null;
    }
  };

  useEffect(() => {

    fetchPrices();
  }, []);

  return (
    <PriceContext.Provider value={{ prices }}>
      {children}
    </PriceContext.Provider>
  );
};

export const usePricing = () => useContext(PriceContext);


