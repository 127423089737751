import React from 'react';
import { Container, Typography, Box, Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import enclosureImage from '../../assets/photo/enclosure.png';
import deviceImage from '../../assets/photo/ari01.png';
import plugImage from '../../assets/photo/ari02.png';
import connectImage from '../../assets/photo/ari03.png';
import assyImage from '../../assets/photo/ari04.png';
import battImage from '../../assets/photo/ari05.png';
import antennaImage from '../../assets/photo/ari06.png';
import cableImage from '../../assets/photo/ari07.png';
import wearingImage from '../../assets/photo/wearing.png';
import menuImage from '../../assets/photo/device01.png';
import fillingImage from '../../assets/photo/device02.png';
import bleImage from '../../assets/photo/device03.png';
import devselect from '../../assets/photo/device04.png';
import qpay from '../../assets/photo/device05.png';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { useTranslation } from 'react-i18next';

const DeviceGuide = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  return (
    <Container
      sx={{
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        minHeight: '100vh',
        ml: 2,
        mt: 6,
        borderRadius: 2,
        maxWidth: '90%'
      }}
    >

      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, mb: 4 }}>
        <Box
          sx={{
            flex: 1,
            mb: { xs: 2, sm: 0 },
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img
            src={enclosureImage}
            alt="Arigus"
            style={{
              maxWidth: '90%',
              height: 'auto',
              objectFit: "contain"
            }}
          />
        </Box>
        <Box sx={{ flex: 2, maxWidth: '95%', ml: 3 }}>
          <Typography variant="h7" sx={{ mb: 2, fontWeight: 'bold', color: theme.palette.primary.main, }}>
            {t('guide.device.About Arigus')}
          </Typography>
          <Typography variant="body1" paragraph >
            {t('guide.device.Arigus')}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, mb: 4 }}>
        <Box
          sx={{
            flex: 1,
            mb: { xs: 2, sm: 0 },
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img
            src={deviceImage}
            alt="Arigus"
            style={{
              maxWidth: '90%',
              height: 'auto',
              objectFit: "contain"
            }}
          />
        </Box>
        <Box sx={{ flex: 2, maxWidth: '95%', ml: 3 }}>

          <Typography variant="body1" paragraph >
            <span style={{ fontWeight: 'bold' }}> {t("guide.device.Open the Enclosure")} </span>
            {t("guide.device.Begin by unscrewing the enclosure using the provided screws. Carefully remove the cover to access the internal components.")}
            <br />
            <span style={{ fontWeight: 'bold' }}>{t("guide.device.Remove the PCB")} </span>
            {t("guide.device.Gently lift the PCB (printed circuit board) from the enclosure.")}
            <br />
            <span style={{ fontWeight: 'bold' }}>{t("guide.device.Install the Batteries")} </span>
            {t("guide.device.Insert the two Mno2 batteries halfway into the battery slots. Make sure the battery terminals align with the connectors.")}
            <br />
            <span style={{ fontWeight: 'bold' }}>{t("guide.device.Connect the Wires")} </span>
            {t("guide.device.Connect the battery wires to the PCB carefully, ensuring they are properly secured without any strain.")}
            <br />
            <span style={{ fontWeight: 'bold' }}>{t("guide.device.Reinsert the PCB")} </span>
            {t("guide.device.Place the PCB back into the enclosure, ensuring the white antenna faces upward for optimal signal reception.")}
            <br />
            <span style={{ fontWeight: 'bold' }}>{t("guide.device.Ensure Battery Wires are Secure")} </span>
            {t("guide.device.As you insert the PCB, ensure that the battery wires are positioned in the designated slot to prevent damage or interference.")}
            <br />

          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, mb: 4 }}>
        <Box
          sx={{
            flex: 1,
            mb: { xs: 2, sm: 0 },
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img
            src={plugImage}
            alt="Arigus"
            style={{
              maxWidth: '90%',
              height: 'auto',
              objectFit: "contain"
            }}
          />
        </Box>
        <Box sx={{ flex: 2, maxWidth: '95%', ml: 3 }}>
          <Typography variant="h7" sx={{ mb: 2, fontWeight: 'bold', color: theme.palette.primary.main, }}>
            {t('guide.device.Battery Connector')}
          </Typography>
          <Typography variant="body1" paragraph >
            {t('guide.device.Plug')}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, mb: 4 }}>
        <Box
          sx={{
            flex: 1,
            mb: { xs: 2, sm: 0 },
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img
            src={connectImage}
            alt="Arigus"
            style={{
              maxWidth: '90%',
              height: 'auto',
              objectFit: "contain"
            }}
          />
        </Box>
        
        <Box sx={{ flex: 2, maxWidth: '95%', ml: 3 }}>
          <Typography variant="h7" sx={{ mb: 2, fontWeight: 'bold', color: theme.palette.primary.main, }}>
            {t('guide.device.Plug Connector')}
          </Typography>
          <Typography variant="body1" paragraph >
            {t('guide.device.Pluging')}
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, mb: 4 }}>
        <Box
          sx={{
            flex: 1,
            mb: { xs: 2, sm: 0 },
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img
            src={assyImage}
            alt="Arigus"
            style={{
              maxWidth: '90%',
              height: 'auto',
              objectFit: "contain"
            }}
          />
        </Box>
        <Box sx={{ flex: 2, maxWidth: '95%', ml: 3 }}>
          <Typography variant="h7" sx={{ mb: 2, fontWeight: 'bold', color: theme.palette.primary.main, }}>
            {t('guide.device.Check Board Assembly')}
          </Typography>
          <Typography variant="body1" paragraph >
            {t('guide.device.Check Arigus')}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, mb: 4 }}>
        <Box
          sx={{
            flex: 1,
            mb: { xs: 2, sm: 0 },
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img
            src={battImage}
            alt="Arigus"
            style={{
              maxWidth: '90%',
              height: 'auto',
              objectFit: "contain"
            }}
          />
        </Box>
        <Box sx={{ flex: 2, maxWidth: '95%', ml: 3 }}>
          <Typography variant="h7" sx={{ mb: 2, fontWeight: 'bold', color: theme.palette.primary.main, }}>
            {t('guide.device.Battery Insertion')}
          </Typography>
          <Typography variant="body1" paragraph >
            {t('guide.device.Battery Insertion Text')}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, mb: 4 }}>
        <Box
          sx={{
            flex: 1,
            mb: { xs: 2, sm: 0 },
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img
            src={antennaImage}
            alt="Arigus"
            style={{
              maxWidth: '90%',
              height: 'auto',
              objectFit: "contain"
            }}
          />
        </Box>
        <Box sx={{ flex: 2, maxWidth: '95%', ml: 3 }}>
          <Typography variant="h7" sx={{ mb: 2, fontWeight: 'bold', color: theme.palette.primary.main, }}>
            {t('guide.device.PCB Insertion')}
          </Typography>
          <Typography variant="body1" paragraph >
            {t('guide.device.PCB Insertion Text')}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, mb: 4 }}>
        <Box
          sx={{
            flex: 1,
            mb: { xs: 2, sm: 0 },
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img
            src={cableImage}
            alt="Arigus"
            style={{
              maxWidth: '90%',
              height: 'auto',
              objectFit: "contain"
            }}
          />
        </Box>
        <Box sx={{ flex: 2, maxWidth: '95%', ml: 3 }}>
          <Typography variant="h7" sx={{ mb: 2, fontWeight: 'bold', color: theme.palette.primary.main, }}>
            {t('guide.device.Battery Wires')}
          </Typography>
          <Typography variant="body1" paragraph >
            {t('guide.device.Battery Wires Text')}
          </Typography>
        </Box>
      </Box>
      {/* Section 2 - Image and Text */}
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, mb: 4 }}>
        <Box
          sx={{
            flex: 1,
            mb: { xs: 2, sm: 0 },
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img
            src={wearingImage}
            alt="Create Account"
            style={{
              maxWidth: '90%',
              height: 'auto',
              borderRadius: '8px',
              objectFit: "contain"
            }}
          />
        </Box>
        <Box sx={{ flex: 2, maxWidth: '95%', ml: 3 }}>
          <Typography variant="h7" sx={{ mb: 2, fontWeight: 'bold', color: theme.palette.primary.main, }}>
            {t("guide.device.Wearing Arigus on Livestock")}
          </Typography>
          <Typography variant="body1" paragraph >
            <span style={{ fontWeight: 'bold' }}>{t("guide.device.Attach Arigus to the Collar Strap")} </span>
            {t("guide.device.device to the collar")}
            <br />
            <span style={{ fontWeight: 'bold' }}>{t("guide.device.Adjust the Strap")} </span>
            {t("guide.device.Gently tighten")}
            <br />
            <span style={{ fontWeight: 'bold' }}>{t("guide.device.Position the Battery Section")} </span>
            {t("guide.device.Battery Side")}
            <br />
            <Typography sx={{ borderLeft: "4px solid rgb(9, 105, 218)", p: 2 }}>
              <EditNoteIcon />
              <span style={{ fontStyle: 'italic' }}> {t("guide.device.Steps")}</span>
            </Typography>
          </Typography>
        </Box>
      </Box>
      {/* Section 2 - Image and Text */}
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, mb: 4 }}>
        <Box
          sx={{
            flex: 1,
            mb: { xs: 2, sm: 0 },
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img
            src={menuImage}
            alt="Create Account"
            style={{
              maxWidth: '90%',
              height: 'auto',
              borderRadius: '8px',
              objectFit: "contain"
            }}
          />
        </Box>
        <Box sx={{ flex: 2, maxWidth: '95%', ml: 3 }}>
          <Typography variant="h7" sx={{ mb: 2, fontWeight: 'bold', color: theme.palette.primary.main, }}>
            {t("guide.device.Device Management on Arigus.xyz Platform")}
          </Typography>
          <Typography variant="body1" paragraph >
            {t("guide.device.management")}
          </Typography>
        </Box>
      </Box>
      {/* Section 2 - Image and Text */}
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, mb: 4 }}>
        <Box
          sx={{
            flex: 1,
            mb: { xs: 2, sm: 0 },
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img
            src={fillingImage}
            alt="Create Account"
            style={{
              maxWidth: '90%',
              height: 'auto',
              borderRadius: '8px',
              objectFit: "contain"
            }}
          />
        </Box>
        <Box sx={{ flex: 2, maxWidth: '95%', ml: 3 }}>
          <Typography variant="h7" sx={{ mb: 2, fontWeight: 'bold', color: theme.palette.primary.main, }}>
            {t("guide.device.Registering on Arigus.xyz and Device Setup")}
          </Typography>
          <Typography variant="body1" paragraph >
            {t("guide.device.register")}
          </Typography>
        </Box>
      </Box>
      {/* Section 2 - Image and Text */}
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, mb: 4 }}>
        <Box
          sx={{
            flex: 1,
            mb: { xs: 2, sm: 0 },
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img
            src={bleImage}
            alt="Create Account"
            style={{
              maxWidth: '90%',
              height: 'auto',
              borderRadius: '8px',
              objectFit: "contain"
            }}
          />
        </Box>
        <Box sx={{ flex: 2, maxWidth: '95%', ml: 3 }}>
          <Typography variant="h7" sx={{ mb: 2, fontWeight: 'bold', color: theme.palette.primary.main, }}>
            {t("guide.device.Obtain Device ID via BLE")}
          </Typography>
          <Typography variant="body1" >
            {t("guide.device.id")}
          </Typography>
        </Box>
      </Box>
      {/* Section 2 - Image and Text */}
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, mb: 4 }}>
        <Box
          sx={{
            flex: 1,
            mb: { xs: 2, sm: 0 },
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img
            src={devselect}
            alt="select Device for mode change"
            style={{
              maxWidth: '90%',
              height: 'auto',
              borderRadius: '8px',
              objectFit: "contain"
            }}
          />
        </Box>
        <Box sx={{ flex: 2, maxWidth: '95%', ml: 3 }}>
          <Typography variant="h7" sx={{ mb: 2, fontWeight: 'bold', color: theme.palette.primary.main, }}>
            {t("guide.device.Checking Device registration")}
          </Typography>
          <Typography variant="body1" paragraph >
            {t("guide.device.check")}
          </Typography>
        </Box>
      </Box>
      {/* Section 2 - Image and Text */}
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, mb: 4 }}>
        <Box
          sx={{
            flex: 1,
            mb: { xs: 2, sm: 0 },
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <img
            src={qpay}
            alt="Create Qpay invoice"
            style={{
              maxWidth: '90%',
              height: 'auto',
              borderRadius: '8px',
              objectFit: "contain"
            }}
          />
        </Box>
        <Box sx={{ flex: 2, maxWidth: '95%', ml: 3 }}>
          <Typography variant="h7" sx={{ mb: 2, fontWeight: 'bold', color: theme.palette.primary.main, }}>
            {t("guide.device.Payment for mode switch")}
          </Typography>
          <Typography variant="body1" paragraph >

            <span style={{ fontWeight: 'bold' }}>{t("guide.device.Active")} </span>
            {t("guide.device.ActiveText")} <br />
            <span style={{ fontWeight: 'bold' }}>{t("guide.device.Paused")} </span>
            {t("guide.device.PausedText")}<br />
            <span style={{ fontWeight: 'bold' }}>{t("guide.device.Inactive")} </span>
            {t("guide.device.InactiveText")}<br />
            <span style={{ fontWeight: 'bold' }}>{t("guide.device.Changing Subscription Mode")} </span>
            {t("guide.device.mode")}
          </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default DeviceGuide;
