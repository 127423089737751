import { StrictMode } from "react";
import ReactDOM from "react-dom";
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import "@aws-amplify/ui-react/styles.css";
import { ThemeProvider } from './contexts/ThemeContext'; 

import App from "./App";

import "@fortawesome/fontawesome-free/css/all.min.css";

Amplify.configure(awsconfig);

const rootElement = document.getElementById("root");

ReactDOM.render(
  <StrictMode>
    <ThemeProvider>
      <App />
    </ThemeProvider>,
  </StrictMode>,
  rootElement
);
