import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  MenuItem,
  Select,
  Typography,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Checkbox,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import CustomTextField from './CustomTextField';
import { get, post, put } from 'aws-amplify/api';
import { fetchUserAttributes } from '@aws-amplify/auth';
import { useUserTrackers, refreshUserTrackers } from '../contexts/UserTrackersContext';
import { QRCodeCanvas } from 'qrcode.react';
import { usePricing } from '../contexts/PriceContext';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



const SERVICE_UUID = '8553dc1d-1db7-4cd3-868b-8a527460aa85';
const DEVICE_ID_UUID = '78563412-efcd-ab90-7856-341278563412';
const STOP_FOTA_UUID = '65870921-4365-8709-2143-658721436587';
const WRITE_START_HOURS_UUID = '65870921-4365-8709-2143-658721436688';

const ACTIVE = 0x55;
const PAUSED = 0x33;
const STOPED = 0xFF;

const DUE = 0x35;
const PAID = 0x85;
let bleService = null;

export const DeviceList = ({ onCancel }) => {
  const [selectedDevice, setSelectedDevice] = useState(null);
  const { t } = useTranslation();
  const [newDeviceName, setNewDeviceName] = useState('');
  const [newDeviceId, setNewDeviceId] = useState('');
  const [idError, setIdError] = useState(false);
  const [idErrorMessage, setIdErrorMessage] = useState('');
  const [userId, setUserId] = useState('');
  const [loadingActivate, setLoadingActivate] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);
  // const [loadingCheck, setLoadingCheck] = useState(false);
  const [loadingPause, setLoadingPause] = useState(false);
  const [qrCode, setQrCode] = useState(null);
  const [qrData, setQrData] = useState(null);
  const { userTrackers, refreshUserTrackers } = useUserTrackers();
  const [loadingBle, setLoadingBle] = useState(false);
  // const [city, setCity] = useState('');
  // const [state, setState] = useState('');
  const navigate = useNavigate();
  const theme = useTheme();
  const pathCreateInvoice = '/qpay/create-invoice';
  // const [locations, setLocations] = useState({ lat: 0, lng: 0 });
  const [startHours, setStartHours] = useState([]);
  const [startHoursAdj, setStartHoursAdj] = useState([]);
  const { prices } = usePricing();
  // const [checkedDevice, setChecked] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [agreedToTerms, setAgreedToTerms] = useState(false);

  toast.configure();

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    getUserId();
  }, []);

  const handleCancel = () => {
    navigate('/');
  };

  const getUserId = async () => {
    try {
      const user = await fetchUserAttributes();
      setUserId(user.sub);
    } catch (error) {
      console.error('Error fetching user ID:', error);
    }
  };
  const connectToBleDevice = async () => {
    setLoadingBle(true);
    try {
      const device = await navigator.bluetooth.requestDevice({
        filters: [{ namePrefix: 'Arigus' }],
        optionalServices: [SERVICE_UUID],
      });

      if (!device) {
        //console.log('No device selected.');
        return;
      }

      //console.log('Selected device:', device.name, device.id);
      const server = await device.gatt.connect();
      bleService = await server.getPrimaryService(SERVICE_UUID); // Store the service for later use

      if (!bleService) {
        console.error('Failed to get BLE service.');
        return;
      }

      // Read Device ID immediately upon connecting
      const deviceIdCharacteristic = await bleService.getCharacteristic(DEVICE_ID_UUID);
      const deviceIdValue = await deviceIdCharacteristic.readValue();
      const deviceID = new TextDecoder().decode(deviceIdValue);
      setNewDeviceId(deviceID);

    } catch (error) {
      handleError(error);
    } finally {
      setLoadingBle(false);
    }
  };
  // async function getTimeZone(lat, lng) {
  //   const timestamp = Math.floor(Date.now() / 1000);
  //   const apiKey = 'AIzaSyA8m6UunMfW8_k9lZqMqWRCZ2PT3_aswyg';
  //   const url = `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${timestamp}&key=${apiKey}`;

  //   try {

  //     const response = await fetch(url);
  //     const data = await response.json();

  //     if (data.status === 'OK') {
  //       return data.rawOffset / 3600;
  //     } else {
  //       console.error("Error fetching timezone:", data.errorMessage);
  //       return null;
  //     }
  //   } catch (error) {
  //     console.error("Error fetching timezone:", error);
  //     return null;
  //   }
  // }

  const writeStartHoursToDevice = async (startHoursArray) => {
    try {
      //if (startHoursArray.length >= 5) 
      //{
      const startHoursCharacteristic = await bleService.getCharacteristic(WRITE_START_HOURS_UUID);

      // Convert to Uint8Array for BLE transmission
      //const startHoursBuffer = new Uint8Array(startHoursArray.slice(0, 5));

      const startHoursBuffer = new Uint8Array([1, 4, 9, 14, 18]);
      await startHoursCharacteristic.writeValue(startHoursBuffer);
      //console.log('Start hours written to the device successfully:', startHoursBuffer);
      // } else {
      //   console.error("Insufficient data: Need at least 5 elements in startHoursArray.");
      // }
    } catch (error) {
      console.error('Error writing start hours to device:', error);
    }
  };



  const sendStopFotaCommand = async () => {
    try {
      const stopFotaCharacteristic = await bleService.getCharacteristic(STOP_FOTA_UUID);
      const command = new Uint8Array([0x01]);
      await stopFotaCharacteristic.writeValue(command);
      bleService = null;
      //console.log('FOTA stopped successfully.');
    } catch (error) {
      //console.error('Error stopping FOTA:', error);
    }
  };

  const handleError = (error) => {
    if (error instanceof DOMException) {
      console.error('Bluetooth connection failed:', error.message);
    } else {
      console.error('Unexpected error:', error);
      alert('An unexpected error occurred. Please try again.');
    }
  };

  // const handleCheck = async () => {
  //   setLoadingCheck(true);
  //   try {
  //     // Fetch GPS coordinates from HERE API
  //     const hereResponse = await fetch(`https://geocode.search.hereapi.com/v1/geocode?q=${city}+${state}&apiKey=HQLbkVCB7VKkyfvcdaLIN0R_Q_oWyy-fLbGCl6wZkDM`);
  //     const hereData = await hereResponse.json();
  //     if (hereData.items.length === 0) {
  //       setIdError(true);
  //       setIdErrorMessage(t('device.Not found place.'));
  //       return;
  //     }

  //     const { lat, lng } = hereData.items[0].position;
  //     setLocations(hereData.items[0].position);

  //     console.log("location:", lat, lng);
  //     // Fetch satellite pass data from Astrocast API
  //     let responset;
  //     try {
  //       const restOperation = post({
  //         apiName: 'apiArigusService',
  //         path: '/satellitePasses',
  //         options: {
  //           body: {
  //             lat: lat,
  //             lng: lng
  //           },
  //           headers: {
  //             'Content-Type': 'application/json'
  //           }
  //         }
  //       });

  //       const { body } = await restOperation.response;
  //       responset = await body.json();
  //       console.log("ephemeris:", responset);

  //       //setStartHours(responset.slice(0, 5));
  //       console.log("ephemeris:", startHours);
  //       const timeOffset = await getTimeZone(lat, lng);

  //       setStartHours(responset.slice(0, 5));
  //       if (timeOffset) {
  //         const adjustedHours = responset.map(hour => {
  //           let adjustedHour = hour + Math.floor(Math.abs(timeOffset));
  //           adjustedHour = (adjustedHour + 24) % 24; // Adjust to be within 0-23 range
  //           return adjustedHour;
  //         });
  //         setStartHoursAdj(adjustedHours.slice(0, 5));
  //         setChecked(true);
  //       }
  //     } catch (error) {
  //       console.error('Error getting Ephemerides:', error);
  //       setIdError(true);
  //       setIdErrorMessage(t('device.Ephemeris not found.'));
  //       return;
  //     }

  //   } catch (error) {
  //     console.error('Unexpected error adding device:', error);
  //   } finally {
  //     setLoadingCheck(false);
  //   }

  // };

  const handleAddDevice = async () => {
    setLoadingAdd(true);
    try {

      await writeStartHoursToDevice(startHours);
      await sendStopFotaCommand();

      // Proceed to add the device with the collected information
      try {
        const deviceIdRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;
        if (!deviceIdRegex.test(newDeviceId)) {
          setIdError(true);
          setIdErrorMessage(t('device.Invalid Device Id Format'));
          setLoadingAdd(false);
          return;
        }
        const restOperation = post({
          apiName: 'apiArigusService',
          path: '/addTracker',
          options: {
            body: {
              userId: userId,
              trackerId: newDeviceId,
              nameTracker: newDeviceName,
              statusTracker: ACTIVE,
            },
          },
        });

        const { body } = await restOperation.response;
        await body.json();
        toast.success(t('Arigus'), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
        toast.success(t('device.Device added successfully!'), {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });

      } catch (error) {
        console.error('Error adding tracker:', error);
        setIdError(true);
        setIdErrorMessage(t('device.Failed to register device.'));
        return;
      }

      // Refresh the trackers and reset form fields
      refreshUserTrackers();
      setNewDeviceName('');
      setNewDeviceId('');
      // setCity('');
      // setState('');
      setIdError(false);
      setIdErrorMessage('');
      setAgreedToTerms(false);

      // setLocations({ lat: 0, lng: 0 });
      // setStartHours([]);
      // setChecked(false);
    } catch (error) {
      console.error('Unexpected error adding device:', error);
    } finally {
      setLoadingAdd(false);
    }
  };

  async function postReceipt(data) {
    try {
      const response = post({
        apiName: 'apiArigusService',
        path: '/ebarimtCreate',
        options: {
          body: data,
          headers: {
            'Content-Type': 'application/json',
          },
        },
      });

      const { body } = await response.response;
      const responset = await body.json();
     // console.log('Receipt posted:', responset);
      setQrData(responset.response.qrData);

    } catch (error) {
      console.error('Error posting receipt:', error);
    }
  }



  // Shared function to handle invoice creation
  const createInvoice = async (actionType) => {
    let senderInvoiceNo;
    if (actionType === ACTIVE) setLoadingActivate(true);
    if (actionType === PAUSED) setLoadingPause(true);

    try {
      const selectedPrice = prices.find(price => price.type === (actionType === ACTIVE ? '0004' : '0005'));
      const totalAmount = selectedPrice ? selectedPrice.fee : 0;
      const description = actionType === ACTIVE ? 'Activation invoice' : 'Total invoice for all payments';
      const invoiceNo = actionType === ACTIVE ? 'Active' : 'Pause';

      // Generate senderInvoiceNo
      const last8DeviceId = selectedDevice?.deviceId?.slice(-4);
      const randomSixDigits = Math.floor(1000 + Math.random() * 9000).toString();
      const currentDate = new Date();
      const day = currentDate.getDate().toString().padStart(2, '0');
      const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
      const year = currentDate.getFullYear();
      senderInvoiceNo = `${invoiceNo}-${last8DeviceId}-${randomSixDigits}-${day}/${month}/${year}`;

      // Create QPay Invoice
      try {
        const restOperation = post({
          apiName: 'apiArigusService',
          path: pathCreateInvoice,
          options: {
            body: {
              senderInvoiceNo: senderInvoiceNo,
              invoiceReceiverCode: selectedDevice?.deviceId || 'RECEIVER_CODE',
              amount: totalAmount,
              description: description,
            },
          },
        });
        const { body } = await restOperation.response;
        const response = await body.json();
        //console.log('Qpay posted:', response);

        if (!response.invoice_id) {
          throw new Error('Failed to retrieve invoice ID.');
        }
        setQrCode(response.qr_image);

      } catch (error) {
        console.error(`Error creating ${actionType} invoice:`, error.message);
        setIdError(true);
        setIdErrorMessage(`Failed to create ${actionType} invoice. Please try again.`);
        return;
      }

      // Record invoice in QPay Billing
      try {
        const createdAt = Date.now();  // ulzii . make it in lambda
        const billingOperation = post({
          apiName: 'apiArigusService',
          path: '/qpayBilling',
          options: {
            body: {
              userId: userId,
              trackerId: selectedDevice.deviceId,
              invoiceID: senderInvoiceNo,
              createdAt: createdAt,
              paymentMonths: 0,
              billingStatus: DUE,
            },
            headers: {
              'Content-Type': 'application/json',
            },
          },
        });
        const billingResponse = await billingOperation.response;
        //console.log('Billing response:', billingResponse);

      } catch (error) {
        console.error('Error recording invoice in QPay Billing:', error);
        setIdError(true);
        setIdErrorMessage('Failed to record invoice in billing. Please try again.');
        return;
      }

      // Post Receipt
      try {
        await postReceipt({
          "totalAmount": "110.00",
          "totalVAT": "10.00",
          // Add other fields as required
        });
      } catch (error) {
        console.error('Error posting receipt:', error);
        setIdError(true);
        setIdErrorMessage('Failed to post receipt. Please try again.');
        return;
      }

    } catch (error) {
      console.error('Unexpected error during invoice creation:', error);
      setIdError(true);
      setIdErrorMessage('An unexpected error occurred. Please try again.');
    } finally {
      if (actionType === ACTIVE) setLoadingActivate(false);
      if (actionType === PAUSED) setLoadingPause(false);
    }
  };
  const handleAgree = (agreed) => {
    setAgreedToTerms(agreed);
  };
  return (
    <Container sx={{ backgroundColor: theme.palette.background.default, color: theme.palette.text.primary, minHeight: '100%', ml: 2, mt: 6 }}>
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          color: theme.palette.primary.main,
          fontSize: { xs: '1.2rem', sm: '1.3rem', md: '1.5rem' }, // Responsive font size
        }}
      >
        {t("device.Device Management")}
      </Typography>
      <Box sx={{ mb: 3, mt: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={9} md={3}>
            <CustomTextField
              label={t("device.New Device Name")}
              variant="outlined"
              fullWidth
              value={newDeviceName}
              onChange={(e) => setNewDeviceName(e.target.value)}
            />
          </Grid>
          <Grid item xs={9} md={3}>
            <CustomTextField
              label={t("device.New Device ID")}
              variant="outlined"
              fullWidth
              value={newDeviceId}
              onChange={(e) => setNewDeviceId(e.target.value)}
              onClick={connectToBleDevice}
              error={idError}
            />
          </Grid>
        </Grid>
        {/* <Grid container spacing={2} mt={1}>
          <Grid item xs={9} md={3}>
            <CustomTextField
              label={t("device.City")}
              variant="outlined"
              fullWidth
              value={city}
              onChange={(e) => setCity(e.target.value)}
            />
          </Grid>
          <Grid item xs={9} md={3}>
            <CustomTextField
              label={t("device.State")}
              variant="outlined"
              fullWidth
              value={state}
              onChange={(e) => setState(e.target.value)}
            />
          </Grid>
        </Grid>
        <Typography variant="caption" display="block" gutterBottom sx={{ mt: 1, width: 'full', color: theme.palette.text.primary, fontStyle: 'italic' }} >
          {locations.lat && locations.lng
            ? `${t("device.Location")}: ${locations.lat}, ${locations.lng}`
            : ''}
          {startHoursAdj.length > 0
            ? ` ${t("device.Schedule")}: ${startHoursAdj}`
            : ''}
        </Typography> */}
        <Box>
          {/* <Button
            variant="contained"
            color="secondary"
            onClick={handleCheck}
            disabled={loadingAdd}
            sx={{ mr: 2, mt: 2, fontSize: '0.700rem', color: theme.palette.text.primary, borderRadius: '12px' }}
            size="small"
          >
            {loadingCheck ? <CircularProgress size={24} /> : t("device.Check Device")}
          </Button> */}
          <div>
            <Checkbox
              checked={agreedToTerms}
              onChange={handleAgree}
            />
            <label>
              <Typography variant="caption" gutterBottom sx={{ width: 'full', color: theme.palette.text.warning, fontStyle: 'italic' }} >
                {t("device.I agree to the")} <a href="#" onClick={() => handleDialogOpen()}>{t("device.Terms of Use")}</a>
              </Typography>
            </label>
          </div>
          <Button
            variant="contained"
            onClick={handleAddDevice}
            // disabled={loadingAdd | !checkedDevice| newDeviceName==''|newDeviceId==''}
            disabled={!agreedToTerms || loadingAdd | newDeviceName === '' | newDeviceId === ''}
            sx={{
              mr: 2, mt: 2, backgroundColor: theme.palette.primary.main,
              '&:hover': { backgroundColor: theme.palette.primary.dark },
              fontSize: '0.700rem', color: theme.palette.text.primary,
              borderRadius: '12px'
            }}
            size="small"
          >
            {loadingAdd ? <CircularProgress size={24} /> : t("device.Add Device")}
          </Button>

        </Box>
        <Dialog open={dialogOpen} onClose={handleDialogClose}>
          <DialogTitle>{t("device.Terms of Use")}</DialogTitle>
          <DialogContent>
            <DialogContentText>              
              НЭГ. НИЙТЛЭГ ЗҮЙЛ<br />
              1.1.	Үйлчилгээ үзүүлэгч нь Хэрэглэгчид өөрийн “Аригус”(цаашид “Төхөөрөмж” гэх) адуу, мал байршил болон бусад мэдээлэлхүргэх төхөөрөмжийг нийлүүлэх, Аригус платформ(цаашид “Платформ” гэх)-ыг төлбөртэйгөөр ашиглуулах Хэрэглэгч нь Үйлилгээ үзүүлэгчид үйлчилгээний болон төхөөрөмжийн зардлыг төлөхтэй холбоотой харилцааг энэхүү Гэрээгээр зохицуулна.   <br />
              1.2.	Энэхүү нөхцөлд заасан нэр томъёог дор дурдсан утгаар ойлгоно.   <br />
              1.2.1.	Төхөөрөмж гэж Аригус төхөөрөмжийг;   <br />
              1.2.2.	Платформ гэж үйлчилгээ үзүүлэгчийн вэбсайтыг;   <br />
              1.2.3.	Үйлчилгээ гэж платформоор дамжуулан хэрэглэгчид үзүүлж буй үйлчилгээг;   <br />
              1.2.4.	Үйлчилгээний төлбөр гэж платформ ашигласны төлбөрийг;   <br />
              1.2.5.	Сарын хураамж гэж сар бүр тогтмол төлөх төлбөрийг;   <br />
              1.2.6.	Төлбөрийн цикл гэж үйлчилгээг идэвхжүүлж, эхний төлбөр төлөгдсөнөөс хойш сараар тооцно;   <br />
              1.2.7.	Нууц код гэж хэрэглэгч өөрийн хувийн мэдээлэлд нэвтрэхдээ ашиглах хэрэглэгчийн сонгосон үсэг, тоо, тэмдэгтүүдийг;   <br />
              1.2.8.	Reset хийх Төхөөрөмжний хавтанд байрлах товч <br />
              1.3.	Хэрэглэгч нь энэхүү бүх нөхцөлтэй танилцаж, төхөөрөмж платформд бүргүүлснээр бүх нөхцөлийг хүлээн зөвшөөрч хүчин төгөлдөр болно.  Энэхүү нөхцөл нь хувилбар нотриат болон блокчэйнд хашаар баталгаажуулан хадгалагдана. Нөхцөл өөрчилгөдөх тухайд хэрэглэгчидэд мэйлээр илгээнэ. Мэйлд зөвшөөрөхгүй гэсэн товчоор хариулаагүй тохиолдолд шинэ нөхцөлийг зөвшөөрсөнд тоооцно. Мэдээлэл тухай бүрт сошиал пэйжээр зарлагдана.   <br />
              <br />
              ХОЁР. ТӨЛБӨРИЙН НӨХЦӨЛ, ХУГАЦАА   <br />
              2.1.	Үйлчилгээ үзүүлэгч нь үйлчилгээний үнэ тарифыг гаргаж хэрэглэгчид мэдэгдэх бөгөөд үйлчилгээний хугацаанаас хамаарч ялгавартай тогтооно.   <br />
              2.2.	Хэрэглэгч нь үйлчилгээ авахын тулд платформд бүртгэл хийлгэж, үйлчилгээний төлбөрийг төлсөн байх шаардлагатай.   <br />
              2.3.	Төлбөрийн мэдээлэл:   <br />
              2.3.1.	Төхөөрөмжийн үнэ 520 мянган+ 96 мянга (3 сар) төгрөг  байна.   <br />
              Платформ ашиглах үйлчилгээний төлбөр:   <br />
              2.3.2.	Нэг сарын төлбөр 35,000(Гучин таван мянга) төгрөг;   <br />
              2.3.3.	3 сараар урьдчилж төлбөл нэг сарын төлбөр 32,000(Гучин хоёр мянга) төгрөг;   <br />
              2.3.4.	6 сараар урьдчилж төлбөл нэг сарын төлбөр 30,000(Гучин мянган) төгрөг байна.   <br />
              2.4.	Энэхүү нөхцөлд 2.3.2, 2.3.3, 2.3.4-т заасан төлбөр нь зөвхөн 1 ширхэг төхөөрөмжинд ногдох төлбөр болно.   <br />
              2.5.	Хэрэглэгч дараагийн төлбөрийн цикл эхлэхээс өмнө дараа сарын төлбөрийг төлнө.   <br />
              2.6.	Төлбөрийг 2.5-д заасан хугацаанд төлөөгүй тохиолдолд платформын үйлчилгээний эрх 7 хоног хадгалагдах ба энэ хугацаанд төлөөгүй тохиолдолд үйлчилгээний эрх цуцлагдана.   <br />
              2.7.	Хэрэглэгч нь үйлчилгээний төлбөрийг www.arigus.xyz вэбсайтаар төлөх боломжтой.   <br />
              2.8.	Хэрэглэгч төхөөрөмжийг ашиглахгүй тохиолдолд 3 хүртэлх сар “түр зогсоох” горимд шилжүүлж болно. Түр зогсоох горимд шилжүүлсэн тохиолдолд нэг сарын төлбөр 6000 төгрөг байна.   <br />
              2.9.	Түр зогсоох горимд шилжүүлэхдээ тракер 5д заасан дагуу хийнэ   <br />
              2.10.	Түр зогсоох горимын хугацаа дуусахад, хэрэглэгч эрхээ сунгуулах хүсэлт гаргаагүй, төлбөрөө төлөөгүй тохиолд хэрэглэгчийн төхөөрөмж ИДЭВХГҮЙ төлөвт шилжинэ.   <br />
              2.11.	Идэвхгүй төлөвт шилжсэн төхөөрөмжийг Платформын “төхөөрөмж” цэснээс ИДЭВХТЭЙ төлөвт шилжүүлж болох ба энэ тохиолдолд 50,000(Гучин мянган) төгрөг төлөх шаардлагатай.   <br /><br />
              ГУРАВ. ТАЛУУДЫН ҮҮРЭГ, ХАРИУЦЛАГА   <br />
              3.1.	Үйлчилгээ үзүүлэгч нь хэрэглэгчийг төхөөрөмж болон платформыг ашиглах гарын авлага, зааварчилгаагаар хангана.   <br />
              3.2.	Үйлчилгээ үзүүлэгч нь Хэрэглэгчийн мэдээллийн аюулгүй байдлыг хангах үүрэгтэй.   <br />
              3.3.	Хэрэглэгч нь гэрээ болон гарын авлагатай бүрэн гүйцэт танилцах үүрэгтэй.   <br />
              3.4.	Хэрэглэгч нь платформыг хууль бус үйлдэл хийхэд ашиглахгүй байх, платформд өөрийн мэдээллээ үнэн зөв оруулах ба өөрийн нууц үгийн аюулгүй байдлыг хариуцна.   <br />
              3.5.	Талууд гэрээний үүргээ санаатай болон санамсаргүйгээр зөрчихгүй байх үүргийг хүлээнэ.   <br />
              3.6.	Хэрэглэгч төхөөрөмжийг ашиглаагүй үедээ идэвхгүй буюу “хаах” төлөвт шилжүүлээгүй тохиолдолд үйлчилгээг авсанд тооцож үйлчилгээний төлбөрийг энэхүү гэрээний 2.3.2-т заасны дагуу төлнө.   <br />
              3.7.	Хэрэглэгч цахим гарын авлагатай танилцсан байх ба төхөөрөмж ашиглах зааврыг зөрчсөнөөс үүдсэн доголдлын хариуцлагыг Хэрэглэгч хариуцна.   <br />
              3.8.	Интернет урсгал тасалдах, сансарын хиймэл дагуулын орчинд хямрал үүсэх зэрэг нөхцөл байдал үүссэн тохиолдолд үйлчилгээ доголдож болно.   <br />
              3.9.	Үйлчилгээний чанарыг сайжруулах зорилгоор системийн болон техникийн сайжруулалт, шинэчлэлтээс хамаарч үйлчилгээ доголдож болох ба энэ тохиолдолд Үйлчилгээ үзүүлэгч өөрийн сувгуудаар дамжуулан олон нийтэд мэдэгдэнэ.   <br />
              3.10.	Хэрэглэгчийн ашиглаж байгаа гар утас, зөөврийн комьпютер зэрэг төхөөрөмжөөс шалтгаалсан үйлчилгээний доголдлыг Үйлчилгээ үзүүлэгч хариуцахгүй.   <br />
              3.11.	Хэрэглэгчийн санаатай болон санамсаргүй үйлдлээс шалтгаалан ашиглалтын заавар зөрчигдсөнөөс үүдэн бусдын өмч хөрөнгөнд шууд болон шууд бусаар учирсан хохирлыг Үйлчилгээ үзүүлэгч тал хариуцахгүй.   <br />
              3.12.	Байгалийн гамшиг, олон нийтийг хамарсан эмх замбараагүй байдал, халдварт өвчин гарах, онц ноцтой гал түймэр дэгдэх, дайны болон онц байдал зарлах зэрэг гэнэтийн болон давагдашгүй хүчин зүйлийн улмаас Гэрээгээр хүлээсэн үүргээ бүгдийг, эсхүл аль нэгийг нь биелүүлээгүй бол ийнхүү биелүүлээгүйн улмаас гарах үр дагаварт Талууд хариуцлага хүлээхгүй.   <br /><br />
              ДӨРӨВ. НЭМЭЛТ ӨӨРЧЛӨЛТ ОРУУЛАХ   <br />
              4.1.	Энэхүү үйлчилгээний нөхцөл нь хэрэглэгч төхөөрөмж бүртгүүлсэн өдрөөс эхлэн хүчин төгөлдөр болно.   <br />
              4.2.	Үйлчилгээний нөхцөл хавсралт болон бүтээгдэхүүн, үйлчилгээний нөхцөл, үнэ тарифт нэмэлт өөрчлөлт оруулах бол Үйлчилгээ үзүүлэгч нь өөрийн сувгуудыг ашиглан 10 хоногоос доошгүй хугацааны өмнө мэдэгдэнэ. Ийнхүү мэдэгдсэнээр нэмэлт өөрчлөлт орсонд тооцно.   <br />
              ТАВ. ТӨХӨӨРӨМЖИЙГ АШИГЛАХ, БАТАЛГААТ ЗАСВАР   <br />
              5.1.	Төхөөрөмж нь мэдээллийг өдөр 5 хүртэлх удаа дамжуулна. Энэ дамжуулалтыг Аригус цаг ямагт найдвартай дамжжулах бөгөөд хиймэл дагуулын операторын серверээс манай платформд шууд илгээгдэнэ.   <br />
              5.2.	Төхөөрөмжийг “түр зогсоох” горимд шилжүүлэхдээ төхөөрөмжийн батерейг салган, түр зогсоох үйлдэлийг платформ дээр гүйцэтгэнэ. Заавартай танилцана уу   <br />
              5.3.	Түр горимд шилжүүлсэн төхөөрөмжийг буцаан идэвхтэй горимд шилжүүлэхэдээ батерейг залган, “Reset” хийгээд анивчиж байхад нь таглаад зүүнэ. Үүнээс эхлээд идэвхтэй горимд шилжинэ. Заавал 3 сар хүлээхгүй идэвхтэй горим руу шилжүүлж болно. Зөрөө бодогдохгүй   <br />
              5.4.	Төхөөрөмжийн баталгаат хугацаа худалдан авалт хийснээс хойш 3(гурван) сар байна.   <br />
              5.5.	Доголдолтой төхөөрөмжийг зөвхөн Эфимерис ХХК-ийн байранд худалдан авалтын баримт, гэрээг үндэслэн хүлээн авна.   <br />
              5.6.	Доголдолтой төхөөрөмжийн доголдлыг засварлах боломжгүй тохиолдолд төхөөрөмжийг буцаан авна.   <br />
              5.7.	Үйлчилгээ үзүүлэгч нь доголдолтой төхөөрөмжийн оронд өөр доголдолгүй төхөөрөмжийг Хэрэглэгчид шилжүүлсэн бол өмнө нь шилжүүлсэн доголдолтой төхөөрөмжийг буцаан авахаар шаардах эрхтэй. Хэрэглэгч нь тухайн төхөөрөмжийн хайрцаг, сав, дагалдах хэрэгслүүдийг иж бүрдлээр нь хүлээлгэн өгнө.   <br />
              5.8.	Хэрэглэгч төхөөрөмжийг худалдан авсан баримт, гэрээний хувиа авчирсан тохиолдолд баталгаат хугацаа үйлчилнэ. Эдгээр баримтууд байхгүй, бичилтийг өөрчилсөн, арилгасан, засварласан тохиолдолд батлагааны журам үйлчлэхгүй болно.   <br />
              5.9.	Дараах тохиолдлуудад баталаат засвар үйлчлэхгүй болно.   <br />
              5.9.1.	Төхөөрөмжийг зориулалтын бусаар ашигласан;   <br />
              5.9.2.	Бусад газраар засвар хийлгэсэн, схем, дизайныг өөрчилсөн, лацыг хөдөлгөсөн, унагаасан;   <br />
              5.9.3.	Гадна болон дотор эд ангийн бүх төрлийн механик гэмтэл;   <br />
              5.9.4.	Дотор нь гадны биет, бодис, ус шингэн шавж орсноос үүдсэн гэмтэл   <br />
              5.9.5.	Байгалийн гамшигт үзэгдэл, түймэр, гадаад хүчин зүйлүүдийн нөлөөллөөс үүдэн гарсан гэмтэл   <br />
              <br />
              ЗУРГАА. БУСАД ЗҮЙЛ   <br />
              6.1.	Энэхүү нөхцөлийг хэрэглэгчийн мэйлээр илгээнэ   <br />
              6.2.	Үйлчилгээ үзүүлэгч гэрээг цахим хэлбэрээр хадгалах цаасан хэлбэрээр нотриатаар баталгаажуулах бөгөөд хэрэглэгчийн бүртгүүлсэн имэйл хаяг руу цахим хувийг илгээнэ.   <br />
              6.3.	Энэхүү нөхцөлтэй холбогдон гарах аливаа маргааныг Талууд харилцан зөвшилцөх замаар шийдврэлэхийг эрмэлзэх бөгөд харилцан зөвшилцөлд хүрээгүй тохиолдолд Монгол Улсын шүүхээр шийдвэрлүүлнэ.   <br />

            </DialogContentText>
          </DialogContent>
        </Dialog>
        {idError && <Typography variant="caption" display="block" gutterBottom sx={{ mt: 1, color: theme.palette.text.warning, fontStyle: 'italic' }} >{idErrorMessage}</Typography>}
      </Box>

      <Box sx={{ mt: 3 }}>
        <Typography variant="h7" gutterBottom sx={{ color: theme.palette.text.secondary }}>
          {t("device.Select Device")}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={9} md={4}>
            <Select
              fullWidth
              value={selectedDevice?.deviceId || ''}
              onChange={(event) => {
                const deviceId = event.target.value;
                const device = userTrackers.find((d) => d.deviceId === deviceId);
                setSelectedDevice(device);
                setQrCode(null); // Clear QR code when selecting a new device
              }}
              size="small"
            >
              {userTrackers.map((device) => (
                <MenuItem key={device.deviceId} value={device.deviceId}>
                  {device.deviceName}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        </Grid>
      </Box>

      {selectedDevice && (
        <Box sx={{ mt: 4 }}>
          <Typography sx={{ color: theme.palette.text.secondary }}>{t("device.Device Name")}: {selectedDevice.deviceName}</Typography>
          <Typography sx={{ color: theme.palette.text.secondary }}>{t("device.Device ID")}: {selectedDevice.deviceId}</Typography>
          <Typography sx={{ color: theme.palette.text.secondary }}>{t("device.Paid for")}: {selectedDevice.billingMonth}</Typography>
          <Typography sx={{ color: theme.palette.text.secondary }}>{t("device.Registered at")}:{new Date(selectedDevice.dateRegister*1000).toLocaleString()}</Typography>
        </Box>
      )}
      <Box>
        <Button
          variant="contained"
          onClick={() => createInvoice(ACTIVE)}
          disabled = {true}
          //disabled={loadingActivate || !selectedDevice || selectedDevice.deviceStatus == ACTIVE}
          sx={{
            mr: 2, mt: 2, backgroundColor: theme.palette.primary.main,
            '&:hover': { backgroundColor: theme.palette.primary.dark },
            fontSize: '0.700rem', color: theme.palette.text.primary,
            borderRadius: '12px'
          }}
          size="small"
        >
          {loadingActivate ? <CircularProgress size={24} /> : t("device.Activate")}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => createInvoice(PAUSED)}
          // disabled={loadingPause || !selectedDevice || selectedDevice.deviceStatus == PAUSED}
          disabled = {true}
          sx={{ mr: 2, mt: 2, fontSize: '0.700rem', color: theme.palette.text.primary, borderRadius: '12px' }}
          size="small"
        >
          {loadingPause ? <CircularProgress size={24} /> : t('billing.Pause')}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleCancel}
          disabled={true}
         // disabled={loadingPause || !selectedDevice || selectedDevice.deviceStatus == STOPED}
          sx={{ mt: 2, fontSize: '0.700rem', color: theme.palette.text.primary, borderRadius: '12px' }}
          size="small"
        >
          {t("device.Deactivate")}
        </Button>
        <Typography variant="caption" display="block" gutterBottom sx={{ mt: 1, width: 'full', color: theme.palette.text.warning, fontStyle: 'italic' }} >
          {t("device.You should pay according to your action on the billing menu.")}
        </Typography>
        {qrCode && (
          <Box sx={{ mt: 4 }}>
            <Card sx={{ display: 'inline-block', p: 2, maxWidth: 500, backgroundColor: 'transparent', boxShadow: 'none' }}>
              <CardContent sx={{ p: 0 }}>
                <Typography variant="h6" gutterBottom sx={{ color: theme.palette.primary.main }}>
                  {t("billing.Scan QR Code to Pay")}
                </Typography>
                <CardMedia
                  component="img"
                  image={`data:image/png;base64,${qrCode}`}
                  alt="QR Code"
                  sx={{ width: 200, height: 200, backgroundColor: 'transparent' }}
                />
                <Typography variant="caption" display="block" gutterBottom sx={{ mt: 1, color: theme.palette.text.warning, fontStyle: 'italic' }} >
                  {t("billing.Payment will be succeeded after QPay confirmation.")}
                </Typography>
              </CardContent>
            </Card>
          </Box>
        )}
        {qrData && (
          <Box sx={{ mt: 4 }}>
            <Card sx={{ display: 'inline-block', p: 2, maxWidth: 500, backgroundColor: 'transparent', boxShadow: 'none' }}>
              <CardContent sx={{ p: 0 }}>
                <Typography variant="h6" gutterBottom sx={{ color: theme.palette.primary.main }}>
                  {t("billing.Scan QR Code to Pay")}
                </Typography>
                <QRCodeCanvas value={qrData} size={192} />
                <Typography variant="caption" display="block" gutterBottom sx={{ mt: 1, color: theme.palette.text.warning, fontStyle: 'italic' }} >
                  {t("billing.Payment will be succeeded after QPay confirmation.")}
                </Typography>
              </CardContent>
            </Card>
          </Box>
        )}

      </Box>
    </Container>
  );
};

export default DeviceList;
