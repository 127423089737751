import React from 'react';
import { Container, Typography, Grid, Card, CardMedia, CardContent, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import enclosureImage from '../assets/photo/enclosure.jpg';
import registeringImage from '../assets/photo/user.jpg';
import smsImage from '../assets/photo/smsicon.jpg'
import mapImage from '../assets/photo/mapview.png'
import markerImage from '../assets/photo/marker.png'
import qa from '../assets/photo/Q&A.png'
import { useTranslation } from 'react-i18next';

const UserGuide = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const sections = [
        {
            id: 1,
            title: t('guide.blog.User Registration'),
            description: t('guide.blog.Learn how to create an account and get started with our platform.'),
            link: '/guide/register',
            image: registeringImage
        },
        {
            id: 2,
            title: t('guide.blog.About the Device'),
            description: t('guide.blog.Understand the GPS device features and specifications.'),
            link: '/guide/device',
            image: enclosureImage
        },
        {
            id: 3,
            title: t('guide.blog.About the SMS'),
            description: t('guide.blog.How to send location to Arigus Map.'),
            link: '/guide/sms',
            image: smsImage
        },
        {
            id: 4,
            title: t('guide.blog.Working with Map'),
            description: t('guide.blog.How To use Arigus Map.'),
            link: '/guide/map',
            image: mapImage
        },
        {
            id: 5,
            title: t('guide.blog.Working with Markers'),
            description: t('guide.blog.How to manage markers.'),
            link: '/guide/marker',
            image: markerImage
        },
        {
            id: 6,
            title: t('guide.blog.Q&A'),
            description: t('guide.blog.Most asked questions.'),
            link: '/guide/questions',
            image: qa
        },

        // Add other sections here
    ];

    return (
        <Container
            sx={{
                backgroundColor: theme.palette.background.default,
                color: theme.palette.text.primary,
                minHeight: '100vh',
                ml: 8,
                mt: 2,
                p: 4,
                borderRadius: 2,
            }}
        >
            <Typography
                variant="h5"
                gutterBottom
                sx={{
                    color: theme.palette.primary.main,
                }}
            >
                {t("guide.blog.Arigus Tracker User Guide")}
            </Typography>

            <Grid container spacing={2}>
                {sections.map((section) => (
                    <Grid item xs={9} sm={5} md={3} key={section.id} >
                        <Card sx={{
                            height: '100%', backgroundColor: theme.palette.background.highlight, borderRadius: 2, cursor: 'pointer',
                            '&:hover': {
                                boxShadow: 3, // Adds a subtle shadow on hover for better feedback
                            },
                        }}
                            onClick={() => navigate(section.link)}
                        >
                            <CardMedia
                                component="img"
                                height="170"
                                image={section.image}
                                alt={section.title}
                            />
                            <CardContent sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                <Typography
                                    variant="h7"
                                    fontWeight={'bold'}
                                    color='theme.palette.text.primary'
                                >
                                    {section.title}
                                </Typography>
                                <Typography
                                    variant="body1"
                                    color='theme.palette.text.primary'
                                >
                                    {section.description}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default UserGuide;
