import React, { useState, useEffect } from 'react';
import { Box, Button, Container, Grid, MenuItem, Select, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Pagination, Paper, TextField } from '@mui/material';
import { useTheme, useMediaQuery } from '@mui/material';
import { useUserTrackers } from '../contexts/UserTrackersContext';
import { useTranslation } from 'react-i18next';

const DeviceReport = () => {
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [reportData, setReportData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const { userTrackers } = useUserTrackers();
  const theme = useTheme();
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();


  const handleDeviceSelect = (event) => {
    const deviceId = event.target.value;
    const selected = userTrackers.find((device) => device.deviceId === deviceId);
    setSelectedDevice(selected || null);
  };


  const fetchReportData = async (pageNumber) => {
    //console.log(`Fetching report data from: ${fromDate}, to: ${toDate}`);
  };

  const handleFetchReport = () => {
    if (selectedDevice) fetchReportData(page);
  };

  useEffect(() => {
    if (selectedDevice && fromDate && toDate) fetchReportData(page);
  }, [selectedDevice, fromDate, toDate, page]);

  return (
    <Container sx={{ backgroundColor: theme.palette.background.default, color: theme.palette.text.primary, minHeight: '100%', ml: 2, mt: 6 }}>
      <Typography
        variant="h5"
        gutterBottom
        sx={{
          color: theme.palette.primary.main,
          fontSize: { xs: '1.2rem', sm: '1.3rem', md: '1.5rem' }, // Responsive font size
        }}
      >
        {t("device.Device Report")}
      </Typography>

      {/* Device Selection */}

      <Typography variant="h7" gutterBottom sx={{ color: theme.palette.text.secondary }}>
        {t("device.Select Device")}
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={9} md={3}>
          <Select
            fullWidth
            value={selectedDevice?.deviceId || ''}
            onChange={handleDeviceSelect}
            size="small"
            sx={{
              height: '35px',
              fontSize: '0.875rem',
              padding: '6px 8px',
              maxWidth: '300px'
            }}
          >
            {userTrackers.map((device) => (
              <MenuItem key={device.deviceId} value={device.deviceId}>
                {device.deviceName}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>

      {/* Date Range Selection */}
      <Box sx={{ mt: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={9} md={3}>
            <TextField
              label={t("billing.From Date")}
              type="date"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={fromDate}
              onChange={(e) => setFromDate(e.target.value)}
              size="small"
              sx={{
                height: '35px',
                fontSize: '0.875rem',
                padding: '6px 8px',
                maxWidth: '300px'
              }}
            />
          </Grid>
          <Grid item xs={9} md={3}>
            <TextField
              label={t("billing.To Date")}
              type="date"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={toDate}
              onChange={(e) => setToDate(e.target.value)}
              size="small"
              sx={{
                height: '35px',
                fontSize: '0.875rem',
                padding: '6px 8px',
                maxWidth: '300px'
              }}
            />
          </Grid>
          <Grid item xs={9} md={4}>
            <Button
              variant="contained"
              onClick={handleFetchReport}
              sx={{
                backgroundColor: theme.palette.primary.main,
                '&:hover': { backgroundColor: theme.palette.primary.dark },
                fontSize: '0.700rem',
                color: theme.palette.text.primary,
                borderRadius: '12px',
                width: isSmallDevice ? '100%' : 'auto',
                mt: '12px'
              }}
              size="small"
            >
              {t("billing.Report")}
            </Button>
          </Grid>
        </Grid>
      </Box>

      {/* Report Table */}
      <Box sx={{ mt: 4 }}>
        <Typography variant="h7" gutterBottom sx={{ color: theme.palette.text.secondary }}>
          {t("billing.Payment Report")}
        </Typography>
        <Box sx={{ maxWidth: '80%' }}>

          <Table size="small" sx={{ borderCollapse: 'separate', borderSpacing: '0 2px' }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ padding: '8px' }}>{t("billing.Date")}</TableCell>
                <TableCell sx={{ padding: '8px' }}>{t("billing.Type")}</TableCell>
                <TableCell sx={{ padding: '8px' }}>{t("billing.Amount")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reportData.map((row) => (
                <TableRow key={row.date}>
                  <TableCell sx={{ padding: '8px' }}>{row.date}</TableCell>
                  <TableCell sx={{ padding: '8px' }}>{row.paymentType}</TableCell>
                  <TableCell sx={{ padding: '8px' }}>{row.amount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>

        </Box>

        {/* Pagination */}
        {totalPages > 1 && (
          <Pagination
            count={totalPages}
            page={page}
            onChange={(event, value) => setPage(value)}
            sx={{ mt: 2 }}
          />
        )}
      </Box>
    </Container >
  );
};

export default DeviceReport;
